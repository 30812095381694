var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTSelect',{attrs:{"multiple":"","items":_vm.bundleList,"return-object":"","attach":false,"label":_vm.label,"placeholder":_vm.placeholder,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.selectedSize)?_c('TTChip',{attrs:{"close":"","close-icon":"fal fa-times"},on:{"click:close":_vm.clearSelect}},[_vm._v(" "+_vm._s(_vm.selectedSize)+" ")]):_vm._e()]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.isParent)?_c('span',{staticClass:"tt-text-body-2"},[_vm._v(" "+_vm._s(_vm.$te(("products." + (item.text))) ? _vm.$t(("products." + (item.text))) : item.text)+" "),(index<_vm.selectedSize)?_c('span',[_vm._v(", ")]):_vm._e()]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var selectListAttrs = ref.attrs;
return [_c('VListItem',_vm._b({class:{'ml-3' : !item.isParent},on:{"click":function($event){return _vm.toggleSelect(item)}}},'VListItem',selectListAttrs,false),[_c('div',{staticClass:"d-flex align-center license-list__item"},[_c('TTCheckbox',{attrs:{"value":selectListAttrs.inputValue,"label":_vm.$te(("products." + (item.text))) ? _vm.$t(("products." + (item.text))) : item.text,"on-icon":_vm.checkAllChildIsSelected(item)}}),_c('VSpacer'),(item.issued && item.issued.length!==0)?_c('div',{staticClass:"d-flex align-center tooltip_wrapper"},[_c('TTTooltip',{attrs:{"bottom":"","right":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tt-text-caption tt-light-yellow--text"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$tc('pluralize.licence_select', item.issued.length, { value : item.issued.length }))+" "),_c('VIcon',{staticClass:"ml-1",attrs:{"small":"","color":"tt-light-yellow"}},[_vm._v(" fal fa-question-circle ")])],1)]}}],null,true)},[_c('div',{staticClass:"flex-column"},_vm._l((item.issued),function(licence,i){return _c('div',{key:i,staticClass:"d-flex"},[_c('VSpacer'),_vm._v(_vm._s(_vm.$t(("licenses.type." + (licence.type))))+" • С "+_vm._s(licence.from)+" до "+_vm._s(licence.to)+" ")],1)}),0)])],1):_vm._e()],1)])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }