var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VRow',[_c('VCol',{attrs:{"cols":12}},[_c('TTDataTable',_vm._g({attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"disable-pagination":true},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex flex-row align-start"},[_c('div',{staticClass:"d-inline-flex"},[_c('TTCheckbox',{staticClass:"product-checkbox",on:{"change":_vm.updateSelectedItems},model:{value:(value.checked),callback:function ($$v) {_vm.$set(value, "checked", $$v)},expression:"value.checked"}})],1),_c('div',{staticClass:"d-inline-block"},[_c('div',{staticClass:"tt-light-mono-100--text"},[_vm._v(" "+_vm._s(_vm.$te(("products." + (value.appName))) ? _vm.$t(("products." + (value.appName))) : value.appName)+" ")]),_c('div',{staticClass:"tt-light-mono-64--text"},[_vm._v(" "+_vm._s(_vm.$te(("products." + (value.bundleName) + "_bundle")) ? _vm.$t(("products." + (value.bundleName) + "_bundle")) : value.bundleName)+" ")])])])]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tt-light-mono-100--text"},[_vm._v(" "+_vm._s(item.users.total)+" чел. ")]),_c('div',{staticClass:"tt-light-mono-64--text"},[_vm._v(" Осталось "+_vm._s(item.users.left)+" ")])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tt-light-mono-100--text"},[_vm._v(" "+_vm._s(item.duration.days)+" "+_vm._s(_vm.$tc('pluralize.day', item.duration.days))+" ")]),_c('div',{staticClass:"tt-light-mono-64--text"},[_vm._v(" Осталось "+_vm._s(item.duration.left)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tt-light-mono-100--text"},[_vm._v(" "+_vm._s(item.date.from)+" ")]),_c('div',{staticClass:"tt-light-mono-64--text"},[_vm._v(" До "+_vm._s(item.date.to)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v(_vm._s(_vm.$t(("licenses.type." + (item.type)))))]),_c('div',{staticClass:"row-pointer mr-2"},[_c('VMenu',{attrs:{"allow-overflow":"","offset-y":"","content-class":"table-v-menu__wrapper","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VIcon',_vm._g(_vm._b({staticClass:"tt-light-blue--text ml-2",attrs:{"small":""}},'VIcon',attrs,false),on),[_vm._v(" far fa-ellipsis-h ")])]}}],null,true)},[_c('VList',[_c('VListItem',{on:{"click":function($event){return _vm.$emit('deactivate:licences', [item])}}},[_c('VIcon',{staticClass:"tt-light-red--text mr-3",attrs:{"left":""}},[_vm._v(" fal fa-trash-alt ")]),_c('VListItemTitle',{staticClass:"tt-light-red--text"},[_vm._v(" Отозвать лицензию ")])],1)],1)],1)],1)])]}}])},_vm.$listeners))],1),(_vm.selectedItems.length)?_c('VCol',{staticClass:"licenceTable-toolbar",attrs:{"cols":12}},[_c('TTToolbar',[_c('div',{staticClass:"d-flex flex-column flex-md-row py-5 justify-space-between flex-grow-1 align-center"},[_c('TTToolbarTitle',[_vm._v(" "+_vm._s(_vm.licencesPickedCount)+" ")]),_c('div',[_c('TTBtn',{attrs:{"color":"tt-ghost","plain":true},on:{"click":_vm.uncheckedAllLicences}},[_vm._v(" Отменить ")]),_c('TTBtn',{attrs:{"color":"tt-danger","plain":true},on:{"click":function($event){return _vm.$emit('deactivate:licences', _vm.selectedItems)}}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-trash-alt ")]),_vm._v(" Отозвать лицензии ")],1)],1)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }