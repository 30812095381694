<template>
  <TTSelect
    v-model="selected"
    label="Срок действия"
    placeholder="Выберите количество дней"
    :items="daysList"
    multiple
    :disabled="disabled"
  >
    <template #prepend-inner>
      <TTChip
        v-if="range || selected.length"
        close
        close-icon="fal fa-times"
        @click:close="clearSelect"
      >
        {{ range ? 1 : selected.length }}
      </TTChip>
    </template>
    <template #selection="{ item, index}">
      <span
        v-if="range"
        class="tt-text-body-2"
      >
        {{ range }} {{ $tc('pluralize.day', 5) }}
      </span>
      <span
        v-else-if="item.text!=='range'"
        class="tt-text-body-2"
      >
        {{ item.text }} {{ $tc('pluralize.day', item.text) }}<span v-if="index<selected.length-1">,&nbsp;</span>
      </span>
    </template>
    <template #prepend-item>
      <VContainer
        class="range__container"
        fluid
      >
        <VRow>
          <VCol
            cols="12"
            class="tt-text-body-2"
          >
            Укажите диапазон срока действия (дни)
          </VCol>
        </VRow>
        <VForm>
          <VRow align="start">
            <VCol
              cols="6"
              class="pr-2"
            >
              <TTTextField
                v-model="min"
                outlined
                type="number"
                :placeholder="`${dayLimit.min}`"
                class="range__input"
                :disabled="disabledForm"
              >
                <template #prepend-inner>
                  <span class="tt-text-body-2 tt-light-mono-46--text">От</span>
                </template>
              </TTTextField>
            </VCol>
            <VCol
              cols="6"
              class="pl-2"
            >
              <TTTextField
                v-model="max"
                outlined
                type="number"
                :placeholder="`${dayLimit.max}`"
                class="range__input"
                :disabled="disabledForm"
              >
                <template #prepend-inner>
                  <span class="tt-text-body-2 tt-light-mono-46--text">До</span>
                </template>
              </TTTextField>
            </VCol>
          </VRow>
        </VForm>
        <VRow>
          <VCol
            cols="12"
            class="pt-6 pb-0 tt-text-body-2"
          >
            Популярные фильтры
          </VCol>
        </VRow>
        <VRow>
          <VCol
            cols="12"
            class="pb-0"
          >
            <template
              v-for="days in daysList"
            >
              <TTChip
                v-if="days.text!=='range'"
                :key="days.text"
                v-model="days.isSelected"
                class="mr-3 mb-3"
                @click="select(days)"
              >
                {{ days.text }} {{ $tc('pluralize.day', days.text) }}
              </TTChip>
            </template>
          </VCol>
        </VRow>
      </VContainer>
    </template>
    <template #item>
      <VListItem
        class="d-none"
      />
    </template>
  </TTSelect>
</template>

<script>
// TODO: валидация диапазона дней
export default {
  name: 'LicenseDaysFilter',
  props: {
    dayLimit: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      min: null,
      max: null,
      selected: [],
      daysList: [
        { text: 14, isSelected: false },
        { text: 30, isSelected: false },
        { text: 31, isSelected: false },
        { text: 90, isSelected: false },
        { text: 100, isSelected: false },
        { text: 365, isSelected: false },
        { text: 'range', isSelected: false },
      ],
    };
  },
  computed: {
    range() {
      if (this.min || this.max) {
        return `${this.min || this.dayLimit.min} - ${this.max || this.dayLimit.max}`;
      }
      return '';
    },
    disabledForm() {
      return this.selected.length > 0 && !this.range;
    },
  },
  watch: {
    range() {
      const { min, max } = this;
      if (min || max) {
        this.selected = [{ text: 'range', min: min || this.dayLimit.min, max: max || this.dayLimit.max }];
      }
    },
    selected(v) {
      this.$emit('input', v);
    },
  },
  methods: {
    clearSelect() {
      this.min = null;
      this.max = null;
      this.selected = [];
      this.daysList.forEach((e) => { e.isSelected = false; });
    },
    select(i) {
      const item = i;
      if (!item.isSelected) {
        if (this.range) {
          this.min = null;
          this.max = null;
          this.selected = [];
        }
        item.isSelected = true;
        this.selected.push(item);
      } else {
        this.selected = this.selected.filter((d) => d !== item);
        item.isSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.range__container{
  max-width: 317px;
}
::v-deep{
  .v-input__prepend-inner{
    margin-top: 0;
    align-items: center;
    height: 100%;
    .v-chip{
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 5px;
    }
  }
  .range__input {
    .v-input__prepend-inner {
      padding-left: 12px;
    }
    input {
      padding-left: 0;
    }
  }
  .v-select__selections{
    display: block;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    input {
      top:0;
      left: 0;
    }
  }
}
</style>
