<template>
  <VRow>
    <VCol :cols="12">
      <TTDataTable
        :items="items"
        :headers="headers"
        :loading="loading"
        :disable-pagination="true"
        v-on="$listeners"
      >
        <template
          #item.product="{value}"
        >
          <div class="d-flex flex-row align-start">
            <div class="d-inline-flex">
              <TTCheckbox
                v-model="value.checked"
                class="product-checkbox"
                @change="updateSelectedItems"
              />
            </div>
            <div class="d-inline-block">
              <div class="tt-light-mono-100--text">
                {{ $te(`products.${value.appName}`) ? $t(`products.${value.appName}`) : value.appName }}
              </div>
              <div class="tt-light-mono-64--text">
                {{ $te(`products.${value.bundleName}_bundle`)
                  ? $t(`products.${value.bundleName}_bundle`) : value.bundleName }}
              </div>
            </div>
          </div>
        </template>
        <template #item.users="{item}">
          <div class="tt-light-mono-100--text">
            {{ item.users.total }} чел.
          </div>
          <div class="tt-light-mono-64--text">
            Осталось {{ item.users.left }}
          </div>
        </template>
        <template #item.duration="{item}">
          <div class="tt-light-mono-100--text">
            {{ item.duration.days }} {{ $tc('pluralize.day', item.duration.days) }}
          </div>
          <div class="tt-light-mono-64--text">
            Осталось {{ item.duration.left }}
          </div>
        </template>
        <template #item.date="{item}">
          <div class="tt-light-mono-100--text">
            {{ item.date.from }}
          </div>
          <div class="tt-light-mono-64--text">
            До {{ item.date.to }}
          </div>
        </template>
        <template #item.type="{item}">
          <div class="d-flex justify-space-between">
            <div>{{ $t(`licenses.type.${item.type}`) }}</div>
            <div class="row-pointer mr-2">
              <VMenu
                allow-overflow
                offset-y
                content-class="table-v-menu__wrapper"
                left
              >
                <template #activator="{ on, attrs }">
                  <VIcon
                    class="tt-light-blue--text ml-2"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    far fa-ellipsis-h
                  </VIcon>
                </template>
                <VList>
                  <VListItem
                    @click="$emit('deactivate:licences', [item])"
                  >
                    <VIcon
                      class="tt-light-red--text mr-3"
                      left
                    >
                      fal fa-trash-alt
                    </VIcon>
                    <VListItemTitle
                      class="tt-light-red--text"
                    >
                      Отозвать лицензию
                    </VListItemTitle>
                  </VListItem>
                </VList>
              </VMenu>
            </div>
          </div>
        </template>
      </TTDataTable>
    </VCol>

    <VCol
      v-if="selectedItems.length"
      :cols="12"
      class="licenceTable-toolbar"
    >
      <TTToolbar>
        <div class="d-flex flex-column flex-md-row py-5 justify-space-between flex-grow-1 align-center">
          <TTToolbarTitle>
            {{ licencesPickedCount }}
          </TTToolbarTitle>
          <div>
            <TTBtn
              color="tt-ghost"
              :plain="true"
              @click="uncheckedAllLicences"
            >
              Отменить
            </TTBtn>

            <TTBtn
              color="tt-danger"
              :plain="true"
              @click="$emit('deactivate:licences', selectedItems)"
            >
              <VIcon left>
                fal fa-trash-alt
              </VIcon>

              Отозвать лицензии
            </TTBtn>
          </div>
        </div>
      </TTToolbar>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'CompanyLicenseTable',
  props: {
    items: {
      required: true,
      type: Array,
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItems: [],
      headers: [
        {
          text: 'Продукт',
          value: 'product',
          sortable: false,
        },
        {
          text: 'Пользователи',
          value: 'users',
          sortable: false,
        },
        {
          text: 'Срок действия',
          value: 'duration',
          sortable: false,
        },
        {
          text: 'Дата начала',
          value: 'date',
          sortable: false,
        },
        {
          text: 'Тип',
          value: 'type',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    licencesPickedCount() {
      return this.$tc(
        'pluralize.licencesPickedCount',
        this.selectedItems.length,
        { value: this.selectedItems.length },
      );
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.updateSelectedItems();
      },
    },
  },
  methods: {
    updateSelectedItems() {
      this.selectedItems = this.items.filter((item) => item.product.checked);
    },
    uncheckedAllLicences() {
      const newItems = this.items.map((item) => {
        const newItem = { ...item };
        newItem.product.checked = false;
        return newItem;
      });

      this.$emit('update:items', newItems);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-v-menu__wrapper {
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08) !important;

  .expand-team-position {
    padding: 4px 8px;
    border: 1px solid #EBECED;
    box-sizing: border-box;
    border-radius: 5px !important;
    display: flex;
    flex-direction: column;
  }
}

::v-deep {
  .product-checkbox {
    &.v-input--selection-controls {
      margin-top: 0;
    }
  }

  .licenceTable {
    &-toolbar {
      position: sticky;
      bottom: 16px;
    }
  }
}
</style>
