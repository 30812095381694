<template>
  <VDialog
    :value="value"
    max-width="536px"
    @input="$emit('input', $event)"
  >
    <VCard>
      <VCardTitle>
        {{ $tc('pluralize.deactivateLicenceDialog.licences-deactivate-header', licences.length) }}
        без возможности восстановления
      </VCardTitle>

      <VCardText>
        <template v-if="licencesType === 'company'">
          Все пользователи компании потеряют доступ к выбранным продуктам.
        </template>
        <template v-else>
          Все пользователи данной компании и других, привязанных к аккаунту, потеряют доступ к выбранным продуктам.
        </template>
      </VCardText>

      <VCardActions>
        <VSpacer />

        <TTBtn
          color="tt-secondary"
          large
          @click="cancel"
        >
          Отменить
        </TTBtn>

        <TTBtn
          color="tt-danger"
          large
          @click="deactivate"
        >
          <VIcon
            left
          >
            fal fa-trash-alt
          </VIcon>

          {{ $tc('pluralize.deactivateLicenceDialog.deactivate-licences', licences.length) }}
        </TTBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: 'DeactivateLicenceDialog',
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    licences: {
      required: true,
      type: Array,
    },
  },
  computed: {
    licencesType() {
      return this.licences.find((licence) => licence.type === 'account') ? 'account' : 'company';
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    deactivate() {
      this.$emit('deactivate:licences', this.licences);
      this.close();
    },
    cancel() {
      this.$emit('cancel');
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-item__title {
    font-size: 0.875em;
  }

  .v-dialog {
    .v-card {
      .v-card__title {
        font-size: 18px;
        word-break: break-word;
      }
    }
  }

  .v-card__actions > .v-btn.v-btn {
    padding: inherit;
  }
}
</style>
